<template>
  <div id='recharge'>
    <main>
      <!-- 如果需要充值账户余额，请联系客服手机号码： -->
      <div>{{language.l_189}}</div>
      <div><a :href="'tel://' + value">{{value}}</a></div>
    </main>
  </div>
</template>

<script>
import {getBalance} from "@api"

export default {
  data () {
    return {
      value: '',
      language: this.$store.getters.language
    }
  },
  created() {
    this.getData()
  },
  methods:{
    getData() {
      getBalance().then(res => {
        if(res.data.hasOwnProperty('mobile')) {this.value = res.data.mobile.value}
      })
    }
  }
}
</script>

<style lang='less' scoped>
#recharge {
  font-size: 14px;
  main {
    margin: 10px;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    div:nth-child(2) {
      padding-top: 10px;
    }
  }
}
</style>